<template>
  <ClFormRow :label="label" :toolTipText="tooltip" :name="fomKey" ref="selectedMembers">
    <template #content>
      <input
        v-if="isCreateMember || isUpdateMember && !isMultiple"
        v-model="memberRefId"
        type="text"
        :disabled="disabled"
        class="custom-multi-select col-sm-12 input"
      />
      <multiselect
        v-else
        v-model="selectedMembers"
        :options="memberOptions"
        @search-change="onInput"
        :multiple="isMultiple"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
        :disabled="disabled"
        placeholder="Type here..."
      >
        <span slot="noOptions"></span>
        <img
          src="../../assets/icons/search.svg"
          width="16"
          height="16"
          alt="search"
          class="zq--multiselect-search-icon"
        />
      </multiselect>
    </template>
  </ClFormRow>
</template>

<script>
import { formConfig } from "@/config";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ZqSelectMembers",
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    isMultiple: { type: Boolean, default: true },
    fomKey: String,
    disabled: Boolean,
    isCreateMember: Boolean,
    isUpdateMember: Boolean
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      selectedMembers: [],
      memberOptions: [],
      initOptions: null,
      memberRefId: ''
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('members', ['members']),
  },
  watch: {
    memberRefId: {
      deep: true,
      handler(val) {
        if (val && val.length > 0 && this.$refs.selectedMembers) {
          this.$refs.selectedMembers.$el.classList.remove('zq-invalid');
        }
        this.$emit('input', val);
      }
    },
    selectedMembers: {
      deep: true,
      handler(val) {
        if (val && (val.length || Object.keys(val).length) && this.$refs.selectedMembers) {
          this.$refs.selectedMembers.$el.classList.remove('zq-invalid');
        }
        let membersIds = '';
        if (this.isMultiple) {
          membersIds = this.selectedMembers.map(item => item.id);
        } else {
          membersIds = this.selectedMembers.id;
        }
        this.$emit('input', membersIds);
      }
    }
  },
  methods: {
    ...mapActions('members', ['handleGetMembersByQuery']),
    async initialize() {
      this.memberOptions = [];

      this.initOptions = await this.handleGetMembersByQuery({
        queryRequest: {
          sortBy: [
            {
              queryField: 'name',
              order: 'Asc',
            },
          ],
          limit: 20
        }
      });

      this.initOptions.forEach((member) => {
        this.memberOptions.push({
          name: member.memberRefId,
          id: member.id
        })
      });

      if (this.value) {
        if (this.isMultiple) {
          this.memberOptions = this.value;
          this.selectedMembers = this.value.map(id =>
            this.memberOptions.find(member => member.id === id)
          );
        }
        if (!this.isMultiple) {
          this.memberRefId = this.value;
          this.selectedMembers = this.memberOptions.find(m => m.id === this.value)
        }
      }
    },
    async onInput(val) {
      if (val.length >= 1) {
        const members = await this.handleGetMembersByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['memberRefId'],
                queryValue: val
              }
            ]
          }
        });

        members.forEach((member) => {
          this.memberOptions.push({
            name: member.memberRefId,
            id: member.id
          })
        });
      } else {
        if (this.initOptions) {
          this.memberOptions = [];
          this.initOptions.forEach((member) => {
            this.memberOptions.push({
              name: member.memberRefId,
              id: member.id
            })
          });
        } else {
          this.memberOptions = [];
        }
      }
    }
  }
}
</script>

<style lang="scss">

.input {
  height: 40px;
  border: none;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:focus {
    outline: none;
  }
}

.zq-invalid > .zq--form-row--content > .input {
  border: solid 1px var(--zq-warn) !important;
}
</style>
